import axios from 'axios';

async function get(filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`geoplaces`, {...filter })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function getById(id) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`geoplaces/by-id/${id}`)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}


export {
    get,
    getById
}