<template>
  <v-container fluid class="home-content">
    <div class="content row justify-space-around">
      
      <div class="col-12 col-md-6 filters-container order-2 order-md-1">
        <div class="row">
          <div class="col-12 align-self-start ">
            <h1 class="text-h3 title">
              {{ $t("FindTheSuitableCarAndGetOnRoad") }}
            </h1>
          </div>
        </div>

        <div class="mt-7 search-filter">
          <v-form ref="filterForm" class="no-gutters pb-5 pt-6">
            <div class="row">

              <div class="col-md-6 col-xs-12">
                <v-datetime-picker
                  v-model="filter.from"
                  @input="menu2 = false"
                  color="red"
                  :label="$t('ReceivingDate')"
                  :clearText="$t('Cancel')"
                  :okText="$t('Confirm')"
                  :textFieldProps="{
                    class: 'rounded-0',
                    hideDetails: 'auto',
                    appendIcon: 'mdi-calendar',
                    solo: true,
                    color: 'red',
                    rules: [filterRules.required],
                  }"
                  :datePickerProps="{
                    color: 'red',
                    min: dateHelpers.formatDate(new Date()),
                  }"
                  :timePickerProps="{
                    color: 'red',
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon color="red">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon color="red">mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </div>
              <div class="col-md-6 col-xs-12">
                <v-datetime-picker
                  v-model="filter.to"
                  color="red"
                  :label="$t('DeliveringDate')"
                  :clearText="$t('Cancel')"
                  :okText="$t('Confirm')"
                  :textFieldProps="{
                    class: 'rounded-0',
                    hideDetails: 'auto',
                    appendIcon: 'mdi-calendar',
                    solo: true,
                    color: 'red',
                    rules: [filterRules.required],
                  }"
                  :datePickerProps="{
                    color: 'red',
                    min: dateHelpers.formatDate(filter.from),
                  }"
                  :timePickerProps="{
                    color: 'red',
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon color="red">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon color="red">mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </div>

            </div>
            
            <div class="row">
              <div class="col">
                <cities-autocomplete
                    :attrs="{
                      hideDetails: 'auto',
                      appendIcon: 'mdi-map',
                      label: $t('ReceivingLocation'),
                      color: 'red',
                      placeholder: $t('SearchLocation'),
                      rules: [filterRules.required],
                    }"
                    @city-selected="receivingLocationSelected"
                  >
                </cities-autocomplete>
                <v-checkbox
                  v-model="deliverToDifferentLocation"
                  :label="$t('DeliverToDifferentLocation')"
                  hide-details>
                </v-checkbox>
              </div>
              <div v-show="deliverToDifferentLocation" class="col mt-4-xs" :class="calculateLocationPickerWidth">
                <cities-autocomplete
                  :attrs="{
                    hideDetails: 'auto',
                    appendIcon: 'mdi-map',
                    label: $t('DeliveringLocation'),
                    color: 'red',
                    placeholder: $t('SearchLocation'),
                    rules: deliverLocationRules,
                  }"
                  @city-selected="deliveringLocationSelected"
                >
                </cities-autocomplete>
              </div>
              
            </div>
          </v-form>
          <button @click="findVehicle()" class="search-card__cart">
          {{ $t("FindVehicle") }}
        </button>
        </div>
       
      </div>

      <div class="col-12 col-md-6 order-1 order-md-2">
        <img src="../assets/home-main-img.svg" alt="home-main-image"/>
      </div>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import CitiesAutocomplete from "../components/Form/CitiesAutoComplete";
export default {
  name: "Home",
  components: {
    CitiesAutocomplete,
  },
  watch: {
    deliverToDifferentLocation: {
      immediate: true,
      handler(val, oldVal) {
        if (val === false) {
          this.filter.delivering_location = this.filter.receiving_location;
        }
      },
    },
  },
  computed: {
    deliverLocationRules() {
      if (this.deliverToDifferentLocation) {
        return [this.filterRules.required]
      }
      return [];
    },
    calculateLocationPickerWidth() {
      if (this.deliverToDifferentLocation) {
        return 'col-md-6';
      }
      return 'col-md-12';
    }
  },
  data() {
    return {
      menu2: false,
      menu3: false,
      filter: {
        from: new Date(),
        to: new Date(
          Vue.dateHelpers.addDaysToDate(new Date(), 3)
        ),
        receiving_location: null,
        delivering_location: null,
      },
      filterRules: {
        required: (value) => {
          if (!value) {
            return this.$t("FieldRequired");
          }
          return true;
        },
      },
      deliverToDifferentLocation: false,
    };
  },
  methods: {
    findVehicle() {
      if (!this.$refs.filterForm.validate()) return;
      var searchObj = Vue.urlHelper.extractQuery();
      
      if (!this.deliverToDifferentLocation) {
        this.filter.delivering_location = this.filter.receiving_location;
      }

      this.$router.push({
        name: "Vehicles",
        query: {
          ...searchObj,
          from: Vue.dateHelpers.formatDateTime(this.filter.from),
          to: Vue.dateHelpers.formatDateTime(this.filter.to),
          deliverToDifferentLocation: this.deliverToDifferentLocation,
          receiving_location: this.filter.receiving_location.id,
          delivering_location: this.filter.delivering_location.id,
        },
        params: {
          pickupLocation: this.filter.receiving_location,
          deliveryLocation: this.filter.delivering_location 
        }
      });
    },
    receivingLocationSelected(city) {
      this.filter.receiving_location = city;
    },
    deliveringLocationSelected(city) {
      this.filter.delivering_location = city;
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.home-content {

  @media (max-width: 768px) {
    height: 85vh;
    text-align: center;
  }

  @media (max-width: 767px) {
    text-align: center;
    height: auto;
  }
  img {
    width: 100%;
  }
  .title {
    line-height: 1.5em;
    font-weight: bold;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  .search-panel {
    background: transparent;
  }
}


@media (min-width: 992px) {
.filters-container {
    padding-inline-start: 50px;
  }
}

.search-card__cart {
  // box-shadow: 0 7px 99px 0 rgba(204, 51, 66, 0.6);
  background-color: #000000;
  border: none;
  color: #fff;
  width: 100%;
  min-height: 50px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
}
</style>