<template>
  <v-autocomplete
    v-model="city"
    :items="cities"
    hide-selected
    :item-text="calculateCityNameDisplayText"
    item-value="id"
    return-object
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    solo
    class="rounded-0"
    @update:search-input="searchCities"
    @change="citySelected"
    @focus="city = search = null"
    v-bind="attrs"
    :menu-props="{ offsetY: true, closeOnClick: true }"
    loader-height="5"
    single-line
    :error-messages="errorMessages"
  >
    <template v-slot:item="{ item }">
      <div>
        <v-icon
          medium
          v-if="item.feature_code !== 'HTL' && item.feature_code !== 'AIRP'"
        >
          mdi-city
        </v-icon>
        <v-icon medium v-if="item.feature_code == 'AIRP'">
          mdi-airplane
        </v-icon>
        <v-icon medium v-if="item.feature_code == 'HTL'"> mdi-bed </v-icon>
        {{ calculateCityNameDisplayText(item) }}
      </div>
    </template>
    <template v-slot:selection="{ item }">
      <div class="city-selection">
        <v-icon
          medium
          v-if="item.feature_code !== 'HTL' && item.feature_code !== 'AIRPT'"
        >
          mdi-city
        </v-icon>
        <v-icon medium v-if="item.feature_code == 'AIRPT'">
          mdi-airplane
        </v-icon>
        <v-icon medium v-if="item.feature_code == 'HTL'"> mdi-bed </v-icon>
        {{ calculateCityNameDisplayText(item) }}
      </div>
    </template>
  </v-autocomplete>
</template>


<script>
import { get as getCities } from "../../api/cities";
export default {
  name: "CitiesAutoComplete",
  props: {
    attrs: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      city: null,
      cities: [
        {
            "id": 44561,
            "geonameid": 745042,
            "name": "İstanbul",
            "asciiname": "Istanbul",
            "alternatenames": "Iostanbul,Iostanbúl,Ist,Istampoul,Istanbul,Istanbul Province,Istanbul Vilayeti,Miklagard,Miklagård,Provincia de Estambul,Stambul,TR100,astnbwl,isutanburu,yi si tan bu er,İst,İstanbul,İstanbul Vilâyeti,Ισταμπούλ,Истанбул,Стамбул,اسطنبول,イスタンブール,伊斯坦布尔",
            "latitude": "41.0350800",
            "longitude": "28.9833100",
            "feature_class": "A",
            "feature_code": "ADM1",
            "country_code": "TR",
            "admin1_code": "34",
            "population": 14160467,
            "dem": 85,
            "timezone": "Europe/Istanbul",
            "modification_date": "2021-11-13",
            "country": {
                "id": 225,
                "name": "Turkey"
            }
        },
        {
          admin1_code: "34",
          admin2_code: "8521528",
          alternatenames: "ISL,IST,Istanbul Airport,Istanbul New Airport,Istanbul Yeni Havalimani,Istanbuls nya flygplats,LTFM,Neuer Flughafen Istanbul,Novyj aehroport Stambula,Nuevo Aeropuerto de Estambul,frwdgah jdyd astanbwl,isutanburu xin kong gang,mtar astnbwl aljdyd,nml htʻwph hhds sl ʼystnbwl,yi si tan bao xin ji chang,İstanbul Yeni Havalimanı,Новый аэропорт Стамбула,נמל התעופה החדש של איסטנבול,فرودگاه جدید استانبول,مطار إسطنبول الجديد,イスタンブール新空港,伊斯坦堡新機場",
          asciiname: "Istanbul Airport",
          country: {id: 225, name: "Turkey"},
          country_code: "TR",
          dem: 125,
          feature_class: "S",
          feature_code: "AIRP",
          geonameid: 11838481,
          id: 84801,
          latitude: "41.2629000",
          longitude: "28.7424200",
          modification_date: "2019-07-26",
          name: "Istanbul Airport",
          population: 0,
          timezone: "Europe/Istanbul",
        },
        {
          "id": 52671, 
          "geonameid": 6299773, 
          "name": "Sabiha Gökçen International Airport",
          "asciiname": "Sabiha Goekcen International Airport",
          "alternatenames": "Istanbul Sabiha Goekcen International Airport,Istanbul Sabiha Goekcen Uluslararasi Havalimani,Istanbul Sabiha Gökçen International Airport,LTFJ,SAW,Sabiha Goekcen International Airport,Sabiha Goekcen Uluslararasi Havalimani,Sabiha Gökçen International Airport,Sabiha Gökçen Uluslararası Havalimanı,İstanbul Sabiha Gökçen Uluslararası Havalimanı",
          "admin1_code": "34",
          "country": {
            "id": 225, 
            "name": "Turkey"
          },
          "country_code": "TR",
          "dem": 87,
          "elevation": 95,
          "feature_class": "S",
          "feature_code": "AIRP",
          "latitude": "40.8986100",
          "longitude": "29.3091700",
          "modification_date": "2018-07-08",
          "name": "Sabiha Gökçen International Airport",
          "population": 0,
          "timezone": "Europe/Istanbul",
        }
      ],
      isLoading: false,
      search: null,
      errorMessages: [],
    };
  },
  watch: {
    search: {
      immediate: false,
      async handler(val, oldVal) {

        this.validateInput(val);
        // preventing request to fetch all data
        if (!val || val.length <= 2) return;

        // items have already been requested
        if (this.isLoading) return;

        this.isLoading = true;

        this.searchCities(val);
      },
    },
  },
  methods: {
    async searchCities(val) {
      if (!val || val.length <= 2) return;
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        let rq = await getCities({
          params: { asciiname: val },
        });
        if (rq.data.data) {
          this.cities = Array.from(rq.data.data);
        }
        this.isLoading = false;
      }, 1000);
    },
    setCity(city) {
      if (city) {
        this.cities = [...this.cities, city];
        this.city = city;
      }
    },
    calculateCityNameDisplayText(e) {
      var country_name = e.country_code;
      if (e.country) {
        country_name = e.country.name;
      }
      return e.asciiname + ", " + country_name;
    },
    citySelected() {
      this.$emit("city-selected", this.city);
    },
    validateInput(val) { 
      if (val) {
        this.errorMessages = [];
        if (val.length <= 2) {
          this.errorMessages = [
            this.$t("EnterThreeCharactersToSearch"),
          ];
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    console.log('aa', this.attrs.value);
    if (this.attrs.value) {
       this.cities = [...this.cities, this.attrs.value];
       this.city = this.attrs.value;
    }
  },
};
</script>

<style scoped>
.city-selection {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>